import React, { useContext, useEffect, useState } from 'react';
import { moneriumAuth, moneriumCompleteAuthAF, sendToWallet, sendToIban } from 'services/monerium';
import { UserServices } from 'services/UserServices';
import { type IDashboardContext, DashboardContext } from 'components/Dashboard/DashboardContext';
import { formatTokenBalance } from 'utils/formatTokenBalance';
import { useGetUserIndexTokenBalance } from 'hooks/useGetUserIndexTokenBalance';
import { ethers } from 'ethers';
import { useWeb3Auth } from 'services/web3auth';
import { BASE_COIN } from '../blockchain/constants';

export const MoneriumContext = React.createContext({});

export const useMonerium: any = () => {
  return useContext(MoneriumContext);
};

export const MoneriumProvider: any = ({ children }: any) => {
  const [moneriumClient, setMoneriumClient] = useState<any>(null);
  const [balance, setBalance] = useState<any>(null);
  const [iban, setIban] = useState<string | null>(null);
  const [name, setName] = useState<string | null>(null);
  const [moneriumAuthorized, setMoneriumAuthorized] = useState(false);
  const [mId, setMId] = useState<string | null>(null);
  const [kyc, setKyc] = useState<string | null>(null);
  const dashboardContext = useContext<IDashboardContext>(DashboardContext);
  const { wb3athProvider } = useWeb3Auth();

  const getClient: any = async (id: string) => {
    try {
      const data = await moneriumAuth(id);
      if (data !== null) {
        setMoneriumAuthorized(true);
        setMoneriumClient(data?.client);
        setBalance(data.balance.balances[0].amount);
        setName(data?.name);
        setIban(data.accounts.filter((account: any) => account.iban !== undefined)[0].iban);
        setKyc(data.kyc);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const getId: any = async () => {
      const id = await UserServices.getUserMoneriumId();
      setMId(id);

      if (id !== null && !moneriumAuthorized) {
        getClient(id);
      }
    };
    getId();
  }, [mId, moneriumAuthorized, dashboardContext.balance, dashboardContext.account]);

  const sendWithMonerium: any = async (provider: any, transData: any, type: string) => {
    if (type === 'bank') {
      await sendToIban(provider, { ...transData });
    } else {
      await sendToWallet(provider, { ...transData });
    }
    // await fundWithMonerium(provider, { ...transData });
    setTimeout(() => {
      // setting up the balance from chain instead of alchemy
      const ethersProvider = new ethers.providers.Web3Provider(wb3athProvider);
      const signer = ethersProvider.getSigner(dashboardContext.account);

      const fetchBal = useGetUserIndexTokenBalance(BASE_COIN, dashboardContext.account, signer);
      fetchBal.then((res) => {
        dashboardContext.setBalance(formatTokenBalance(Number(res?.toString())) ?? 0);
      });
    }, 2000);
  };

  const completeAuth: any = async (code: string, retrievedCodeVerifier: string) => {
    const data = await moneriumCompleteAuthAF(code, retrievedCodeVerifier);
    await UserServices.setUserMoneriumId(data.profileId);
    setMoneriumAuthorized(true);
    setBalance(data.balance.balances[0].amount);
    setIban(data.accounts.filter((account: any) => account.iban !== undefined)[0].iban);
    setName(data.name);
    setKyc(data.kyc);
  };

  return (
    <MoneriumContext.Provider
      value={{
        moneriumClient,
        balance,
        sendWithMonerium,
        moneriumAuthorized,
        iban,
        name,
        completeAuth,
        kyc,
      }}
    >
      {children}
    </MoneriumContext.Provider>
  );
};
