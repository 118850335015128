import React, { useEffect, useState } from 'react';
import './KYCPopup.scss';

import { moneriumAuth } from 'services/monerium';
import { UserServices } from 'services/UserServices';

interface KYCPopupProps {
  isOpen: boolean;
  onClose: () => void;
}

const KYCPopup: React.FC<KYCPopupProps> = ({ isOpen, onClose }) => {
  const [kycStatus, setKycStatus] = useState<string | null>(null);

  useEffect(() => {
    const getClient: any = async (id: string) => {
      try {
        const data = await moneriumAuth(id);
        if (data !== null) {
          setKycStatus(data.kyc.outcome);
        }
      } catch (error) {
        console.log(error);
      }
    };
    const getId: any = async () => {
      const id = await UserServices.getUserMoneriumId();
      if (id !== null && isOpen) {
        getClient(id);
      }
    };
    getId();
  }, [isOpen]);

  if (!isOpen) return null;

  const isUnderVerificationActive = kycStatus === 'submitted' || kycStatus === 'approved';
  const isVerifiedActive = kycStatus === 'approved';

  return (
    <div className="popup-modal">
      <div className="popup-content">
        <span className="close-button" onClick={onClose}>
          &times;
        </span>
        <div className="modal-header">This process will take 1 working day</div>
        <div className="progress">
          <div className={'step active'}>
            <div className="circle"></div>
            <div className="step-title">Account created</div>
          </div>
          <div className={`step ${isUnderVerificationActive ? 'active' : ''}`}>
            <div className="circle"></div>
            <div className="step-title">Account under verification</div>
          </div>
          <div className={`step ${isVerifiedActive ? 'active' : ''}`}>
            <div className="circle"></div>
            <div className="step-title">Account verified</div>
          </div>
        </div>
        <p className="modal-description">
          Your fund wallet details will be visible after you&lsquo;ve receive an approval email from
          Monerium.
        </p>
      </div>
    </div>
  );
};

export default KYCPopup;
