import React from 'react';
import logo from 'assets/images/fenus-logo.svg';
import './LoadingScreen.scss';

export const InvestmentLoadingScreen = (): JSX.Element => {
  return (
    <div className="loading-screen">
      <div className="loading-container">
        <div className="loading-spinner">
          <div className="loading-progress"></div>
          <img src={logo} alt="Loading Icon" className="loading-logo" />
        </div>

        <h2>Kindly wait while we withdraw your funds</h2>

        <div className="fact-box">
          <p>
            The person or organization that created the &apos;Bitcoin&apos; is unknown, which is the
            most popular and surprising fact about the cryptocurrency world.
          </p>
        </div>
      </div>
    </div>
  );
};
