/* eslint-disable */
import { useContext, useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import './WalletOverviewMobile.scss';
import profileIcon from 'assets/images/profile-icon.svg';
import withdrawIcon from 'assets/images/withdraw-icon.svg';
import fundIcon from 'assets/images/fund-icon.svg';
import { type IDashboardContext, DashboardContext } from './DashboardContext';
// import { formatValue } from 'react-currency-input-field';
import barIconMobile from 'assets/images/bar-icon-mobile.svg';
// import { getCurrencySymbol } from '../../utils/getCurrencySymbol';
import { useWeb3Auth } from 'services/web3auth';
import { useMonerium } from 'context/moneriumContext';
// import { getPricesForAllCoins } from 'services/swapApi';
import { UserServices } from 'services/UserServices';
import { useGetUserIndexTokenBalance } from 'hooks/useGetUserIndexTokenBalance';
import { ethers } from 'ethers';
import { formatTokenBalance } from 'utils/formatTokenBalance';
import { BASE_COIN } from '../../blockchain/constants';
import KYCPopup from 'components/KYCPopup';

interface Props {
  setShowUserProfile: any;
}

export const WalletOverviewMobile = (props: Props): JSX.Element => {
  const { t } = useTranslation('dashboard');
  // const [totalAmount, setTotalAmount] = useState(0);
  const dashboardContext = useContext<IDashboardContext>(DashboardContext);
  const { provider, signMessage, wb3athProvider } = useWeb3Auth();
  const [signature, setSignature] = useState<any>(null);
  const { moneriumAuthorized, kyc } = useMonerium();

  const [balance, setBalance] = useState(0);
  // const [available, setAvailable] = useState(0);
  const [tokenPrices, setTokenPrices] = useState<any>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  useEffect(() => {
    if (dashboardContext.account !== '') {
      const ethersProvider = new ethers.providers.Web3Provider(wb3athProvider);
      const signer = ethersProvider.getSigner(dashboardContext.account);

      const fetchBal = useGetUserIndexTokenBalance(BASE_COIN, dashboardContext.account, signer);
      fetchBal.then((res) => {
        setBalance(formatTokenBalance(Number(res?.toString())) ?? 0);
        dashboardContext.setAvailable(formatTokenBalance(Number(res?.toString())) ?? 0);
        dashboardContext.setAvailableBN(Number(res?.toString()) ?? '0');
      });
    }
  }, [dashboardContext.balance, dashboardContext.portfolio]);

  useEffect(() => {
    const getttingSignature: any = async () => {
      if (provider === null) return;

      const signature = await signMessage('I hereby declare that I am the address owner.');
      setSignature(signature);
      // provider
      //   ?.signMessage('I hereby declare that I am the address owner.')
      //   .then((signature) => {
      //     setSignature(signature);
      //   })
      //   .catch((error) => {
      //     console.error(error);
      //   });
    };
    getttingSignature();
  }, [provider]);

  useEffect(() => {
    // eslint-disable-next-line
    if (tokenPrices === null && window.innerWidth < 675) {
      getPricesAndBalances()
        .then(() => {})
        .catch((e) => {
          console.log(e);
        });
    }
  }, [dashboardContext.portfolio.investments]);

  const getPricesAndBalances: () => Promise<void> = async () => {
    const newInvestments = dashboardContext.portfolio.investments;
    let totalCurrentValue = 0;
    // eslint-disable-next-line array-callback-return
    // const prices = await getPricesForAllCoins();
    // console.log('prices:', prices);
    // setTokenPrices({ ...prices });
    const investedCoins = await UserServices.getInvestedCoins(dashboardContext.account);
    dashboardContext.portfolio.investments.map(async (investment: any, i: number) => {
      const invCoins = investedCoins[`${investment.bundle.name}`][0];
      // console.log('invCoins:', invCoins);

      let currentInvestmentValue = 0;
      invCoins.forEach((coin: any) => {
        // console.log(coin.coin, ' => ', coin.ait, prices[coin.coin], coin.ait * +prices[coin.coin]);
        // const price = !Number.isNaN(+prices[coin.coin]) ? +prices[coin.coin] : coin.value;
        // currentInvestmentValue += coin.ait * price;
        // totalCurrentValue += coin.ait * price;
      });

      newInvestments[i].currentInvestmentValue = currentInvestmentValue;
    });
    dashboardContext.setPortfolio({
      ...dashboardContext.portfolio,
      investments: newInvestments,
      totalCurrentValue,
    });
  };

  // useEffect(() => {
  //   setTotalAmount(dashboardContext.balance + dashboardContext.portfolio.totalCurrentValue);
  // }, [dashboardContext.balance, dashboardContext.portfolio]);

  return (
    <>
      <div className="wallet-overview">
        <div className="wallet-details">
          <Container>
            <Row className="mb-1">
              <Col xs={7}>
                <div className="wallet-total-title">{t('sidebar.total')}</div>
                <div className="wallet-total">
                  {/* {getCurrencySymbol(dashboardContext.currency)}
                  {formatValue({
                    value: alchemyBalance.toString(),
                    // value: totalAmount.toString(),
                    decimalScale: 2,
                  })} */}
                  {dashboardContext.currencyInfo.symbol}
                  {(
                    (balance + dashboardContext.portfolio.totalCurrentValue) *
                    dashboardContext.currencyInfo.rate
                  ).toFixed(2)}
                </div>
              </Col>
              <Col xs={5}>
                <div
                  id="mobile-wallet-icon"
                  className="wallet-icon float-end"
                  onClick={() => {
                    if (!dashboardContext.showGuidedTour) {
                      props.setShowUserProfile(true);
                    }
                  }}
                >
                  <img src={profileIcon} alt="Wallet" height={21} width={20} />
                </div>
              </Col>
            </Row>
            <Row>
              <Col className="text-end d-flex justify-content-end">
                {/* <span className="label">{t('sidebar.available_mobile')}</span>&nbsp;
                <span className="total">
                  {dashboardContext.currencyInfo.symbol}
                  {(alchemyBalance * dashboardContext.currencyInfo.rate).toFixed(2)}
                </span> */}
                <div className="d-flex flex-column" style={{ width: 'fit-content' }}>
                  <span className="label">{t('sidebar.available_mobile')}</span>
                  <span className="total">
                    {dashboardContext.currencyInfo.symbol}
                    {(balance * dashboardContext.currencyInfo.rate).toFixed(2)}
                  </span>
                </div>
                <span className="sep-icon">
                  <img src={barIconMobile} alt="bar separator" />
                </span>
                <div className="d-flex flex-column" style={{ width: 'fit-content' }}>
                  <span className="label">{t('sidebar.value_mobile')}</span>
                  <span className="total">
                    {dashboardContext.currencyInfo.symbol}
                    {(
                      dashboardContext.portfolio.totalCurrentValue *
                      dashboardContext.currencyInfo.rate
                    ).toFixed(2)}
                  </span>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="d-flex">
          <div
            className="withdraw-account"
            onClick={async () => {
              if (!dashboardContext.showGuidedTour) {
                if (moneriumAuthorized === true) {
                  dashboardContext.setShowRetrieveFromWalletPopup(true);
                } else {
                  dashboardContext.setShowMoneriumNotAuthedPopup(true);
                  // dashboardContext.setShowRetrieveFromWalletPopup(true);
                }
              }
            }}
          >
            <img src={withdrawIcon} alt="Withdraw from account" height={12} width={12} />
          </div>
          <div
            className="fund-account w-100 text-center"
            onClick={async () => {
              if (!dashboardContext.showGuidedTour && signature !== null) {
                if (moneriumAuthorized === true) {
                  dashboardContext.setShowFundWalletPopup(true);
                } else if (kyc?.outcome === 'submitted') {
                  openModal();
                } else {
                  dashboardContext.setShowMoneriumNotAuthedPopup(true);
                }
              } else {
                console.log('signature == null');
              }
            }}
          >
            <img src={fundIcon} alt="Fund Wallet" height={12} width={12} />
            {t('sidebar.fund')}
          </div>
        </div>
      </div>
      <div className="mobile-menu-group">
        <NavLink className="mobile-menu" to="/dashboard">
          <span>{t('bundles.investing')}</span>
        </NavLink>
        <NavLink id="mobile-portfolio-link" className="mobile-menu" to="/portfolio">
          <span>{t('sidebar.portfolio')}</span>
        </NavLink>
        <KYCPopup isOpen={isModalOpen} onClose={closeModal} />
      </div>
    </>
  );
};

WalletOverviewMobile.propTypes = {};
