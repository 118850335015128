/* eslint-disable */
import {
  type Balances,
  MoneriumClient,
  type AuthContext,
  type Profile,
  PaymentStandard,
  placeOrderMessage,
} from '@monerium/sdk';
import ethersWeb3Provider from './ethProvider_v5';

const client: MoneriumClient = new MoneriumClient('production');

// eslint-disable-next-line
const PRODUCTION_SECRET = process.env.REACT_APP_monerium_api_key ?? '';
// eslint-disable-next-line

const CLIENT_ID = '7f74017b-6134-11ef-b182-f660b51eb347';
const PRODUCTION_AF = '7f723cbf-6134-11ef-b182-f660b51eb347';

export const moneriumAuth: any = async (id: string) => {
  await client.auth({
    client_id: CLIENT_ID,
    client_secret: PRODUCTION_SECRET,
  });

  const authCtx: AuthContext = await client.getAuthContext();

  if (id !== null && id !== undefined) {
    const currentProfile = authCtx.profiles.filter(
      (profile) => profile.id.toLowerCase() === id.toLowerCase(),
    )[0];

    if (currentProfile !== null && currentProfile !== undefined) {
      const {
        id: profileId,
        accounts,
        name,
        kyc,
      }: Profile = await client.getProfile(currentProfile.id);
      const balances: Balances[] = await client.getBalances(profileId);
      const balance = balances[0];
      return { client, profileId, accounts, balance, name, kyc };
    } else {
      console.error('No profile exists for this id');
      return null;
    }
  } else {
    console.error('No current profile id found');
    return null;
  }
};

export const sendToIban: any = async (
  provider: any,
  data: { walletAddress: string; currency: string; amount: string; iban: string; name: string },
) => {
  const { walletAddress, currency, amount, iban, name } = data;
  const walletProvider = ethersWeb3Provider(provider);

  const addressOnChain = `${iban.split(' ').join('')}`;
  const message = placeOrderMessage(amount, addressOnChain);
  const orderSignature = await provider?.signMessage(message);

  return await client.placeOrder({
    amount,
    signature: orderSignature,
    address: walletAddress,
    counterpart: {
      identifier: {
        standard: PaymentStandard.iban,
        iban: iban.split(' ').join(''),
      },
      details: {
        firstName: name.split(' ')[0],
        lastName: name.split(' ')[1],
        country: 'IS',
      },
    },
    message,
    memo: 'Powered by Monerium SDK',
    network: 'mainnet',
    chain: 'polygon',
  });
};

export const sendToWallet: any = async (
  provider: any,
  data: { walletAddress: string; currency: string; amount: string; address: string; iban: string },
) => {
  const walletProvider = ethersWeb3Provider(provider);

  const { walletAddress, currency, amount, address, iban } = data;
  const addressOnChain = `${address} on ethereum`;
  const message = placeOrderMessage(amount, addressOnChain);
  const orderSignature = await provider?.signMessage(message);

  return await client.placeOrder({
    amount,
    signature: orderSignature,
    address: walletAddress,
    chain: 'polygon',
    network: 'mainnet',
    counterpart: {
      identifier: {
        standard: PaymentStandard.iban,
        iban: iban,
      },
      details: {
        firstName: 'User',
        lastName: 'Userson',
        country: 'IS',
      },
    },
    message,
    memo: 'Powered by Monerium SDK',
  });
};

export const fundWithMonerium: any = async (
  provider: any,
  data: { walletAddress: string; currency: string; amount: string; iban: string },
) => {
  const { walletAddress, currency, amount, iban } = data;
  const addressOnChain = `${'0x48b611940CfF8deF13Be331CE4f9FcfFCBc5f781'} on ethereum`;
  const message = placeOrderMessage(amount, addressOnChain);

  const orderSignature = await provider?.signMessage(message);

  return await client.placeOrder({
    amount,
    signature: orderSignature,
    address: walletAddress, // user wallet address
    counterpart: {
      identifier: {
        standard: PaymentStandard.iban,
        iban: iban.split(' ').join(''),
      },
      details: {
        firstName: 'User',
        lastName: 'Userson',
        country: 'IS',
      },
    },
    message,
    memo: 'Powered by Monerium SDK',
    network: 'mainnet',
    chain: 'polygon',
  });
};

export const moneriumAuthAF: (walletAddress: string, signature: any) => Promise<void> = async (
  walletAddress: string,
  signature: any,
) => {
  const authFlowUrl = client.getAuthFlowURI({
    client_id: PRODUCTION_AF,
    redirect_uri: `${window.location.href.split('dashboard')[0]}monerium-integration`,
    network: 'mainnet',
    chain: 'polygon',
    address: walletAddress,
    signature,
  });

  if (client.codeVerifier !== undefined) {
    window.localStorage.setItem('myCodeVerifier', client.codeVerifier);
  }

  window.location.replace(authFlowUrl);

  await client.auth({
    client_id: CLIENT_ID,
    client_secret: PRODUCTION_SECRET,
  });
};

export const moneriumCompleteAuthAF: any = async (
  authCode: string,
  retrievedCodeVerifier: string,
) => {
  await client.auth({
    client_id: process.env.REACT_APP_MONERIUM_PRODUCTION_AF ?? '',
    code: authCode,
    code_verifier: retrievedCodeVerifier,
    redirect_uri:
      process.env.REACT_APP_domain !== undefined
        ? `${process.env.REACT_APP_domain}/monerium-integration`
        : 'https://test-app.fenus.io/monerium-integration',
  });
  const ctx = await client.getAuthContext();

  const { id: profileId, accounts, name }: Profile = await client.getProfile(ctx.profiles[0].id);
  const balances: Balances[] = await client.getBalances(profileId);
  const balance = balances[0];

  return { profileId, accounts, balance, name };
};
