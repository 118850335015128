import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './TransactionSuccess.scss';
import successIcon from 'assets/images/success-icon.svg';

const WithdrawalSuccess: React.FC = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const timer = setTimeout(() => {
      navigate('/dashboard');
    }, 5000);

    return () => {
      clearTimeout(timer);
    };
  }, [navigate]);

  return (
    <div className="transaction-success-screen">
      <div className="success-container">
        <img src={successIcon} alt="Success" className="success-icon" />
        <h2>Congratulations! You&lsquo;ve successfully withdrawn the funds!</h2>
      </div>
    </div>
  );
};

export default WithdrawalSuccess;
