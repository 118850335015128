import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './TransactionFailed.scss';
import failedIcon from 'assets/images/failed-icon.svg';

const TransactionFailed: React.FC = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const timer = setTimeout(() => {
      navigate('/dashboard');
    }, 5000);

    return () => {
      clearTimeout(timer);
    };
  }, [navigate]);
  return (
    <div className="transaction-failed-screen">
      <div className="failed-container">
        <img src={failedIcon} alt="Failed" className="failed-icon" />
        <p>
          Action failed: please retry at a later moment. If the problem persists, please reach out
          to {''}
          <a href="mailto:info@fenus.io" className="contact-link">
            info@fenus.io
          </a>
          .
        </p>
      </div>
    </div>
  );
};

export default TransactionFailed;
