import { Contract, ethers } from 'ethers';
import { POLYGON_ADDRESSES } from '../blockchain/addresses/Polygon';
import { ExchangeIssuanceZeroExABI } from '../blockchain/abis/ExchangeIssuanceZeroExABI';
import { getIssuanceSwapQuote } from '../utils/getIssuanceSwapQuote';
import { useContext, useState } from 'react';
import { DashboardContext, type IDashboardContext } from '../components/Dashboard/DashboardContext';
import { toast } from 'react-toastify';
import toastWarningIcon from '../assets/images/toast-warning-icon.svg';
import { setAllowance } from './useSetAllowance';
import { UserServices } from '../services/UserServices';
import type { WithdrawalData, Web3AuthProviders } from 'types';
import { coinsConfigs } from '../blockchain/tokensConfig';
import { ONE } from '../blockchain/constants';

export const useMutationRedeemSetToken = (web3AuthProviders: Web3AuthProviders) => {
  const dashboardContext = useContext<IDashboardContext>(DashboardContext);
  const [checkoutStatus, setCheckoutStatus] = useState('');
  const [txStatus, setTxStatus] = useState<string | null>(null);

  /**
   * Hook to handle the redemption of Set Tokens.
   *
   * @param {WithdrawalData} bundle - The withdrawal bundle containing investment details.
   * @param {number | null} indexTokenPrice - The price of the index token.
   */

  const redeemSetToken = async (bundle: WithdrawalData, indexTokenPrice: number | null) => {
    if (!bundle.investment) {
      console.error('No bundle selected.');
      return;
    }

    if (!web3AuthProviders.wb3athProvider) {
      toast.warn('Web3 authentication provider is not initialized', {
        icon: () => <img src={toastWarningIcon} alt="Warning" />,
      });
      return;
    }

    if (bundle.withdrawAmount > bundle.investment.currentInvestmentValue) {
      toast.warn('Insufficient balance', {
        icon: () => <img src={toastWarningIcon} alt="Warning" />,
      });
      return;
    }

    if (!web3AuthProviders) {
      console.error('Blockchain provider not available.');
      return { ethersProvider: null, signer: null };
    }

    const ethersProvider = new ethers.providers.Web3Provider(web3AuthProviders.wb3athProvider);
    const address = await web3AuthProviders?.provider?.getAddress();
    if (!address) {
      console.error('Address is undefined');
      return { ethersProvider: null, signer: null };
    }

    const signer = ethersProvider.getSigner(address);
    if (!signer) {
      console.error('No signer provided');
      setCheckoutStatus('Signer not available');
      return;
    }

    const allowanceSet = await setAllowance(
      signer,
      bundle.withdrawAmount,
      bundle.investment.bundle.name.toLowerCase(),
    );
    if (!allowanceSet.approved) {
      toast.warn('Unable to set allowance');
      return;
    }

    try {
      if (!indexTokenPrice) {
        console.error('Failed to fetch index token price');
        setTxStatus('Failed to fetch index token price');
        return;
      }

      const parsedWithdrawlAmount = ethers.utils.parseUnits(bundle.withdrawAmount.toString(), 18);
      const parsedIndexTokenPrice = ethers.utils.parseUnits(indexTokenPrice.toString(), 18);
      const parsedIndexTokenAmount = parsedWithdrawlAmount
        .mul(ethers.constants.WeiPerEther)
        .div(parsedIndexTokenPrice);

      const ExchangeIssuanceZeroX = new Contract(
        POLYGON_ADDRESSES.ExchangeIssuanceZeroEx,
        ExchangeIssuanceZeroExABI,
        signer,
      );

      if (!bundle) {
        console.error('No withdrawal selected');
        setTxStatus('No withdrawal selected');
        return;
      }

      const { components, positions } = await ExchangeIssuanceZeroX.getRequiredRedemptionComponents(
        POLYGON_ADDRESSES.BasicIssuanceModule,
        false,
        coinsConfigs[bundle.investment.bundle.name.toLowerCase()].address,
        parsedIndexTokenAmount,
      );

      const { quotes, totalSellAmount } = await getIssuanceSwapQuote(components, positions, false);

      const minWithdrawAmount = totalSellAmount.sub(ethers.utils.parseUnits(ONE.toString(), 18));

      const transactionResponse = await ExchangeIssuanceZeroX.redeemExactSetForToken(
        coinsConfigs[bundle.investment.bundle.name.toLowerCase()].address,
        POLYGON_ADDRESSES.EurE,
        parsedIndexTokenAmount,
        minWithdrawAmount,
        quotes,
        POLYGON_ADDRESSES.BasicIssuanceModule,
        false,
        { gasLimit: 10000000 },
      );
      console.log('transaction response', transactionResponse);

      const receipt = await transactionResponse.wait();
      setTxStatus(receipt.status === 1 ? 'Successful' : 'Failed');
      setCheckoutStatus('Success');
      toast.info('Withdrawal processed successfully');

      if (receipt.status === 1) {
        await UserServices.addUserTransactionSummary(
          dashboardContext.account,
          bundle.investment.bundle,
          -1 * Number(parsedWithdrawlAmount.toString()),
          bundle.investment.bundle.coins,
          Number(parsedIndexTokenAmount.toString()) * -1,
        );
        const savedPortfolio = await UserServices.getPortfolio(dashboardContext.account);
        if (savedPortfolio !== null) {
          dashboardContext.setPortfolio(savedPortfolio);
        }
        return { success: true };
      }
    } catch (error) {
      console.error('Transaction error:', error);
      setCheckoutStatus('Failed');
      // toast.error('Checkout failed');
      return { success: false, error };
    }
  };
  return { redeemSetToken, checkoutStatus, txStatus };
};
