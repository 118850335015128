import { Header } from 'components/Layout/Header';
import { type CartItem, type Fees } from 'types';
import { useContext, useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import { type IDashboardContext, DashboardContext } from './DashboardContext';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import './DashboardMobileCheckout.scss';

import backIcon from 'assets/images/bundle-back-icon.svg';
import { InfoTooltip } from 'components/InfoTooltip';
import { SuccessTransaction } from 'components/SuccessTransaction';
import { useWeb3Auth } from '../../services/web3auth';
import { useMutationIssueSetToken } from '../../hooks/useMutationIssueSetToken';
import { useIndexTokenPrice } from '../../hooks/useIndexTokenPrice';
// import LoadingSpinner from '../LoadingSpinner';
import { useSetAllowanceSubmit } from 'hooks/useSetAllowanceSubmit';
import { LoadingScreen } from 'pages/LoadingScreen';

export const DashboardMobileCheckout = (): JSX.Element => {
  const { t } = useTranslation('dashboard');
  const dashboardContext = useContext<IDashboardContext>(DashboardContext);
  const [subTotal, setSubTotal] = useState(0);
  const [fees, setFees] = useState<Fees>({
    serviceFee: 0,
    networkFee: 0,
    totalFees: 0,
  });
  const [cartItems, setCartItems] = useState<JSX.Element[]>([]);
  const [processedCartItems, setProcessedCartItems] = useState<CartItem[]>([]);
  const [openFees, setOpenFees] = useState(false);
  const [approve, setApprove] = useState(false);
  const [processingCheckout, setProcessingCheckout] = useState(false);
  const [showSuccessTransaction, setShowSuccessTransaction] = useState<boolean>(false);
  const [failedTxs, setFailedTxs] = useState<Record<string, string>>({});
  const [currentCartItem, setCurrentCartItem] = useState<CartItem>();

  const navigate = useNavigate();

  const { provider, wb3athProvider } = useWeb3Auth();
  const web3AuthProviders = { provider, wb3athProvider };
  const { issueSetToken } = useMutationIssueSetToken(web3AuthProviders);
  const { setAllowanceSubmit } = useSetAllowanceSubmit(web3AuthProviders);
  const { getIndexTokenPrice } = useIndexTokenPrice();

  const returnCloseSuccesstransaction: () => void = () => {
    setShowSuccessTransaction(false);
  };
  const handleSetAllowance = async (): Promise<void> => {
    for (const item of dashboardContext.shoppingCartItems) {
      await setAllowanceSubmit(item);
      console.log('allowance submitted');
      setApprove(true);
    }
  };

  const handleSubmit = async (): Promise<void> => {
    // Navigate to the loading screen page
    navigate('/loading');
    setProcessingCheckout(true);
    const newProcessedCartItems: CartItem[] = [];
    const failedTransactions: Record<string, string> = {};

    for (const item of dashboardContext.shoppingCartItems) {
      const indexTokenPrice = await getIndexTokenPrice(item.bundle.name);
      const result = await issueSetToken(item, indexTokenPrice);

      if (result?.success) {
        newProcessedCartItems.push(item);
      } else {
        failedTransactions[item.bundle.key] = result.error;
      }
    }

    setProcessedCartItems(newProcessedCartItems);
    setFailedTxs(failedTransactions);
    setProcessingCheckout(false);
    // Redirect to success or failure page based on transaction result
    if (Object.keys(failedTransactions).length === 0) {
      navigate('/transaction-success'); // Success page
    } else {
      navigate('/transaction-failed'); // Failure page
    }
    dashboardContext.setShoppingCartItems(
      dashboardContext.shoppingCartItems.filter((item) => !newProcessedCartItems.includes(item)),
    );
    // setShowSuccessTransaction(Object.keys(failedTransactions).length === 0);
  };

  useEffect(() => {
    const getItems = (items: CartItem[]): JSX.Element[] => {
      const itemDivs = [];
      let runningTotal = 0;

      for (let loop = 0; loop < items.length; loop++) {
        const item = items[loop];
        setCurrentCartItem(item);
        runningTotal += item.investmentValue;
        itemDivs.push(
          <div key={loop} className="item-container">
            <div className="d-flex item-header">
              <div className="flex-grow-1">{item.bundle.name}</div>
              <div>
                {dashboardContext.currencyInfo.symbol}
                {(item.investmentValue * dashboardContext.currencyInfo.rate).toFixed(2)}
              </div>
            </div>
          </div>,
        );
      }

      const serviceFee = parseFloat((runningTotal * 0).toFixed(2));
      const networkFee = parseFloat(dashboardContext.txFees.toFixed(2));
      const totalFees = serviceFee + dashboardContext.txFees;
      setFees({
        serviceFee,
        networkFee,
        totalFees,
      });
      setSubTotal(runningTotal + serviceFee);
      return itemDivs;
    };

    setCartItems(getItems(dashboardContext.shoppingCartItems));
  }, [dashboardContext.shoppingCartItems, dashboardContext.txFees]);

  return (
    <div className="mobile-checkout">
      {showSuccessTransaction && (
        <SuccessTransaction
          key={currentCartItem?.bundle.key}
          close={returnCloseSuccesstransaction}
          withdraw={false}
          items={processedCartItems}
          failedTxs={failedTxs} // TODO remove failed txs
        />
      )}
      <div className="d-flex">
        <div
          className="align-middle"
          onClick={() => {
            navigate('/dashboard');
          }}
        >
          <img className="checkout-back" src={backIcon} alt="Back" height={18} />
        </div>
        <div className="flex-grow-1 text-center">
          <Header />
        </div>
      </div>
      <div className="checkout-header">{t('checkout.investment')}</div>
      <div className="d-flex required-payment">
        <div className="flex-grow-1 ">{t('checkout.pay')}</div>
        <div>
          {/* €{formatValue({ value: subTotal.toString(), decimalScale: 2 })} */}
          {dashboardContext.currencyInfo.symbol}
          {(
            (subTotal - dashboardContext.txFees - fees.serviceFee) *
            dashboardContext.currencyInfo.rate
          ).toFixed(2)}
        </div>
      </div>
      <div className="checkout-investment">
        <div className="checkout-investment-label">{t('checkout.in')}</div>
        <div className="cart-items">{cartItems}</div>
      </div>
      <div className="transaction-fees">
        <div
          className="fees-header d-flex"
          onClick={() => {
            setOpenFees(!openFees);
          }}
          aria-controls="example-collapse-text"
          aria-expanded={openFees}
        >
          <div className="flex-grow-1">{t('checkout.fees1')}</div>
          <div>
            {/* €{formatValue({ value: fees.totalFees.toString(), decimalScale: 2 })} */}
            {dashboardContext.currencyInfo.symbol}
            {(
              (fees.serviceFee + dashboardContext.txFees) *
              dashboardContext.currencyInfo.rate
            ).toFixed(2)}
            <InfoTooltip tooltip={t('checkout.content1')} />
          </div>
          {/* <div className="fees-icon">
            {openFees ? (
              <img src={closeIcon} alt="close" width={15} height={7.5} />
            ) : (
              <img src={openIcon} alt="open" width={15} height={7.5} />
            )}
          </div> */}
        </div>
        {/* <Collapse in={openFees}>
          <div>
            <div className="d-flex fee-details">
              <div className="flex-grow-1">
                {t('checkout.fees2')} <InfoTooltip tooltip={t('checkout.fees3')} />
              </div>
              <div>
                {/* €{formatValue({ value: fees.serviceFee.toString(), decimalScale: 2 })}
                {dashboardContext.currencyInfo.symbol}
                {(fees.serviceFee * dashboardContext.currencyInfo.rate).toFixed(2)}
              </div>
            </div>
            <div className="d-flex fee-details">
              <div className="flex-grow-1">
                {t('checkout.fees4')} <InfoTooltip tooltip={t('checkout.content1')} />
              </div>
              <div>
                {/* €
                {formatValue({
                  value: (fees.networkFee + fees.swapFee).toString(),
                  decimalScale: 2,
                })}
                {dashboardContext.currencyInfo.symbol}
                {(dashboardContext.txFees * dashboardContext.currencyInfo.rate).toFixed(2)}
              </div>
            </div>
          </div>
        </Collapse> */}
      </div>
      <div className="">
        {!approve ? (
          <Button
            variant="primary"
            className="w-100 process-button submit-button"
            onClick={handleSetAllowance}
            disabled={approve}
          >
            Approve
          </Button>
        ) : (
          <Button
            variant="primary"
            className="w-100 process-button submit-button bg-red"
            onClick={handleSubmit}
            disabled={dashboardContext.shoppingCartItems?.length < 1 || processingCheckout}
          >
            {processingCheckout ? <LoadingScreen /> : t('checkout.submit')}
          </Button>
        )}
        <div className="invest-terms">
          {t('checkout.warning1')}
          <a href="#">{t('checkout.warning2')}</a>
        </div>
      </div>
    </div>
  );
};
