import { useContext, useEffect, useState } from 'react';
import { DashboardContext, type IDashboardContext } from '../DashboardContext';
import { Modal, Button } from 'react-bootstrap';
import './PortfolioWithdraw.scss';
import { useTranslation } from 'react-i18next';

import { InfoTooltip } from 'components/InfoTooltip';
import { type WithdrawalData } from 'types';
import { SuccessTransaction } from 'components/SuccessTransaction';
import { useMutationRedeemSetToken } from '../../../hooks/useMutationRedeemSetToken';
import { useWeb3Auth } from '../../../services/web3auth';
import { useIndexTokenPrice } from '../../../hooks/useIndexTokenPrice';

import { useNavigate } from 'react-router-dom';
import { InvestmentLoadingScreen } from 'pages/InvestmentLoadingScreen';

interface IFees {
  serviceFee: number;
  networkFee: number;
  totalFees: number;
}

const truncate = (str: string): string => {
  return str.length > 10 ? str.substring(0, 9) + '...' : str;
};

export const PortfolioWithdraw = (): JSX.Element => {
  const { t } = useTranslation('portfolio');
  const dashboardContext = useContext<IDashboardContext>(DashboardContext);
  const [subTotal, setSubTotal] = useState(0);
  const [fees, setFees] = useState<IFees>({
    serviceFee: 0,
    networkFee: 0,
    totalFees: 0,
  });
  const [cartItems, setCartItems] = useState<JSX.Element[]>([]);
  const [processedCartItems, setProcessedCartItems] = useState<any[]>([]);
  const [openFees, setOpenFees] = useState(false);
  const [openTerms, setOpenTerms] = useState(false);
  const [processingWithdrawal, setProcessingWithdrawal] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [showSuccessTransaction, setShowSuccessTransaction] = useState<boolean>(false);

  const { provider, wb3athProvider } = useWeb3Auth();
  const web3AuthProviders = { provider, wb3athProvider };
  const { redeemSetToken } = useMutationRedeemSetToken(web3AuthProviders);
  const { getIndexTokenPrice } = useIndexTokenPrice();
  const navigate = useNavigate();
  const handleResize = (): void => {
    setIsMobile(window.innerWidth < 768);
  };

  const handleClose = (): void => {
    if (!processingWithdrawal) dashboardContext.setShowWithdraw(false);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [dashboardContext.showWithdraw]);

  const returnCloseSuccesstransaction: () => void = () => {
    setShowSuccessTransaction(false);
  };

  const handleSubmit = async (): Promise<void> => {
    navigate('/investment-loading');
    setProcessingWithdrawal(true);
    setProcessedCartItems([dashboardContext.withdrawInvestment]);
    if (!dashboardContext.withdrawInvestment.investment) {
      console.error('No withdraw bundle selected');
      return;
    }
    const indexTokenPrice = await getIndexTokenPrice(
      dashboardContext.withdrawInvestment.investment?.bundle.name,
    );
    if (!indexTokenPrice) {
      console.error('No index token price');
      return;
    }

    const result = await redeemSetToken(dashboardContext.withdrawInvestment, indexTokenPrice);

    setProcessingWithdrawal(false);
    console.log(result?.success, result?.error);
    if (result?.success) {
      navigate('/withdrawal-success');
      setShowSuccessTransaction(true);
      handleClose();
    } else {
      navigate('/transaction-failed');
    }
  };

  // const getPerformanceFigures = (performance: number): string => {
  //   const prefix = performance >= 0 ? '+' : '';
  //   return formatValue({
  //     value: performance.toString(),
  //     decimalScale: 2,
  //     prefix,
  //   });
  // };

  useEffect(() => {
    const getItem = (item: WithdrawalData): JSX.Element[] => {
      const itemDivs: JSX.Element[] = [];
      let runningTotal = 0;

      if (item.investment === undefined) {
        return itemDivs;
      }

      runningTotal += item.withdrawAmount;
      itemDivs.push(
        <div key={1} className="item-container">
          <div className="d-flex item-header">
            <div className="flex-grow-1">{item.investment.bundle.name}</div>
            {/* <div className="">
              €{formatValue({ value: item.withdrawAmount.toString(), decimalScale: 2 })}
            </div> */}
            <div>
              {dashboardContext.currencyInfo.symbol}
              {(item.withdrawAmount * dashboardContext.currencyInfo.rate).toFixed(2)}
            </div>
          </div>
          {/* <div className="d-flex item-performance">
            <div className="flex-grow-1">
              {item.investment.bundle.performance === 0
                ? getPerformanceFigures(item.investment.bundle.performance)
                : '--'}
              %
            </div>
          </div> */}
        </div>,
      );

      const serviceFee = parseFloat((runningTotal * 0).toFixed(2));
      const networkFee = parseFloat(dashboardContext.txFees.toFixed(2));
      const totalFees = serviceFee + dashboardContext.txFees;
      setFees({
        serviceFee,
        networkFee,
        totalFees,
      });
      setSubTotal(runningTotal + serviceFee);
      return itemDivs;
    };

    setCartItems(getItem(dashboardContext.withdrawInvestment));
  }, [dashboardContext.withdrawInvestment, dashboardContext.txFees]);

  return (
    <>
      {showSuccessTransaction && (
        <SuccessTransaction
          close={returnCloseSuccesstransaction}
          withdraw={true}
          items={processedCartItems}
          failedTxs={{}}
        />
      )}
      <Modal
        show={dashboardContext.showWithdraw && !isMobile}
        onHide={handleClose}
        animation={false}
        className="withdraw-modal d-none d-md-block"
        centered
      >
        <Modal.Header>
          <Modal.Title>{t('withdraw.review')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex required-payment">
            <div className="flex-grow-1">{t('withdraw.getback')}</div>
            {/* <div>€{formatValue({ value: subTotal.toString(), decimalScale: 2 })}</div> */}
            <div>
              ~ {dashboardContext.currencyInfo.symbol}
              {(
                (subTotal - (fees.serviceFee + dashboardContext.txFees)) *
                dashboardContext.currencyInfo.rate
              ).toFixed(2)}
            </div>
          </div>
          <div className="cart-items">{cartItems}</div>
          <div className="transaction-fees">
            <div
              className="fees-header d-flex"
              onClick={() => {
                setOpenFees(!openFees);
              }}
              aria-controls="open fees"
              aria-expanded={openFees}
            >
              <div className="flex-grow-1">Transaction fees</div>
              {/* <div>€{formatValue({ value: fees.totalFees.toString(), decimalScale: 2 })}</div> */}
              <div>
                {dashboardContext.currencyInfo.symbol}
                {(
                  (fees.serviceFee + dashboardContext.txFees) *
                  dashboardContext.currencyInfo.rate
                ).toFixed(2)}
                <InfoTooltip tooltip={t('checkout.content1')} />
              </div>
              {/* <div className="fees-icon">
                {openFees ? (
                  <img src={closeIcon} alt="close" width={15} height={7.5} />
                ) : (
                  <img src={openIcon} alt="open" width={15} height={7.5} />
                )}
              </div> */}
            </div>
            {/* <Collapse in={openFees}>
              <div>
                <div className="d-flex fee-details">
                  <div className="flex-grow-1">
                    {t('checkout.fees2')} <InfoTooltip tooltip={t('checkout.fees3')} />{' '}
                  </div>
                  <div>
                    {dashboardContext.currencyInfo.symbol}
                    {(fees.serviceFee * dashboardContext.currencyInfo.rate).toFixed(2)}
                  </div>
                </div>
                <div className="d-flex fee-details">
                  <div className="flex-grow-1">
                    {t('checkout.fees4')} <InfoTooltip tooltip={t('checkout.content1')} />{' '}
                  </div>
                  <div>
                    {dashboardContext.currencyInfo.symbol}
                    {(dashboardContext.txFees * dashboardContext.currencyInfo.rate).toFixed(2)}
                  </div>
                </div>
              </div>
            </Collapse> */}
          </div>
          <div className="destination-wallet d-flex">
            <div className="flex-grow-1 wallet-text">{t('withdraw.walletID')}</div>
            <div className="wallet-id">{truncate(dashboardContext.account)}</div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="buttons-container">
            <div className="back-button">
              <Button
                className="w-100 process-button d-none d-md-block cancel-button"
                onClick={handleClose}
                disabled={processingWithdrawal}
              >
                {t('withdraw.back')}
              </Button>
            </div>
            <div className="submit-button-container">
              <Button
                className="w-100 process-button submit-button"
                onClick={handleSubmit}
                disabled={processingWithdrawal}
              >
                {processingWithdrawal ? <InvestmentLoadingScreen /> : t('withdraw.submit')}
              </Button>
            </div>
          </div>
          <div className="invest-terms">
            {t('withdraw.warning1')}{' '}
            <span
              className="tnc-link"
              onClick={() => {
                dashboardContext.setShowWithdraw(false);
                setOpenTerms(true);
              }}
            >
              {t('withdraw.warning2')}
            </span>
          </div>
        </Modal.Footer>
      </Modal>
      <Modal show={openTerms && !isMobile} animation={false} centered className="terms-modal">
        <Modal.Header>
          <Modal.Title>Terms and Conditions</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          By using Fenus, you agree to assume full responsibility for any risks associated with your
          use, including market volatility, smart contract vulnerabilities, and loss of funds. All
          transactions on our platform are final and irreversible, and we are not responsible for
          any errors or mistakes. Prohibited activities include illegal actions such as money
          laundering or terrorist financing. Our platform is provided &quot;as is&quot; without any
          warranties, and we are not liable for any damages arising from your use. We reserve the
          right to update these terms without prior notice, and these terms shall be governed by the
          laws of the applicable jurisdiction.
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="w-100 terms-button"
            onClick={() => {
              dashboardContext.setShowWithdraw(true);
              setOpenTerms(false);
            }}
          >
            I got it
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
